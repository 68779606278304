// link styles

// unvisited link
a:link {
    color: #ff0000;
}

// visited link
a:visited {
    color: #00ff00;
}

// mouse over link
a:hover {
    color: #ff00ff;
}

// selected link
a:active {
    color: #0000ff;
}
